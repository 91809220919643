import React, { useState } from "react";
import {FormGroup} from "reactstrap";
import UploadModal from "./UploadModal"
/*
 * file Upload fields for forms
 * Author: Andrew Pickard
 */

function FileUpload(props) {

  return (
      <div>
        <FormGroup className="upload">
          <a href="" onClick={(e) => {e.preventDefault(); props.setDisplay(true)}}>{props.name}</a>
        </FormGroup>
      </div>
    )
  
  }

export default FileUpload

