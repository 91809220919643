import axios from "axios";

const baseURL = "http://127.0.0.1:8000/purchase/";

const apiSettings = {

createReceiptEntry: async (data) => {
    let formData = new FormData();
    formData.append('image', data[0]);

    const myNewReceipt = await fetch(baseURL, {
            method: 'POST',
            body: formData,
        }).then((res) => {
            return res;
        }).catch((error) => {
            return error.response;
        });

    return myNewReceipt;
    },
};

export default apiSettings;

