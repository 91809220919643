import React from "react";
import {FormGroup, Label, Input} from "reactstrap";
/*
 * Text Field for forms
 * Author: Andrew Pickard
 */

function TextField(props) {

    var input = null;
    var br = null;
    var dotted = null;
    var label = null;
    var gridField = null;

    if (props.break === "true") {
      br = (<br></br>);
    }
  
    if (props.readonly == "true") {
      dotted = " dotted "
    }

    if (!(props.gridField == "true")) {
      label = (<Label className="input_label">{props.name}</Label>)
    } else {
      gridField = " grid_field "
    }

    if (props.readonly === "true") {
      input = <Input className={dotted + " textfield " + gridField} type={props.type} id={props.id} readOnly={true} value={props.autofill}/>
    } else {
      input = <Input className={dotted + " textfield " + gridField} type={props.type} id={props.id} readOnly={false} defaultValue={props.autofill}/>
    }

    return (
      <FormGroup className="flex_input">
        {label}
        {br}
        {input}
      </FormGroup>
    )
  
  }

  export default TextField;