import React, { useState } from "react";
import {FormGroup, Button} from "reactstrap";
import UploadModal from "./UploadModal"
/*
 * Add Item button for grids
 * Author: Andrew Pickard
 */

const MODAL_BODY_MESSAGES = {
  save: "Do you wish to save? Your purchase will be saved as a draft for you to come back later.",
  submit: "Do you wish to submit? Your purchase will be routed for approval."
}

//TODO: Refactor this for more reusability 
// (still kinda locked in on Cancel, Save and Submit)
function FormButtons(props) {

  const [display, setDisplay] = useState(false);
  const [modalMessage, setMessage] = useState("");

  return(
    <div>
      <FormGroup className="form_row">
        <div className="flex_input">
          <a id="cancel_button" href="./"><Button onClick={() => document.getElementById("cancel_button").click()} className="anti_button">{props.label1}</Button></a>
        </div>
        <div className="flex_input">
          <Button className="save_button anti_button" onClick={(e) => {
              e.preventDefault();
              setMessage(MODAL_BODY_MESSAGES.save);
              setDisplay(true);
            }}>{props.label2}</Button>
          <Button className="pro_button" onClick={(e) => {
              e.preventDefault();
              setMessage(MODAL_BODY_MESSAGES.submit);
              setDisplay(true);
            }}>{props.label3}</Button>
        </div>
      </FormGroup>
      <UploadModal closeDisplay={() => setDisplay(false)} display={display} confirmLabel="Submit" title="Are you sure?" body={modalMessage}/>
    </div>
  )
}

  export default FormButtons;