import React from "react";
import {FormGroup, Label, Input} from "reactstrap";
/*
 * Yes/No Radio Button fields for forms
 * Author: Andrew Pickard
 */

function ChoiceRadio(props) {

    function setChoice(setting) {
      props.function(setting);
    }

    return (
      <FormGroup className={props.className + " flex_input"}>
        <Label className="input_label">{props.name}</Label>
        <Input className="radio" type="radio" value="true" onClick={() => setChoice(true)} name={props.name} defaultChecked={props.default}/>Yes
        <Input className="radio" type="radio" value="false" onClick={() => setChoice(false)} name={props.name} defaultChecked={!props.default}/>No
      </FormGroup>
    )
}


  export default ChoiceRadio;