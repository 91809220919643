import React, {useState} from "react";
import {Container, Label} from "reactstrap";
import AddItem from "./AddItem.js";
import GridRow from "./GridRow.js";
/*
 * Receipt Info grid for forms
 */

const COLUMN_TYPES = [
    {
        type: "label",
        value: "Item Name",
        size: 2,
    },
    { 
        type: "label",
        value: "Quantity",
        size: 1,
    },
    { 
        type: "label",
        value: "Cost",
        size: 2,
    },
    { 
        type: "label",
        value: "Department",
        size: 1,
        display: {display: 'block'}
    },
    { 
        type: "label",
        value: "Project",
        size: 1,
        display: {display: 'block'}
    },
    { 
        type: "label",
        value: "Project Split",
        size: 2,
        display: {display: 'block'}
    },
    { 
        type: "label",
        value: "Delete",
        size: 1,
    }];

const DEFAULT_ROW_LAYOUT = [
    {
        type: "text",
        value: "Item Name",
        size: 2,
    },
    { 
        type: "text",
        value: "Quantity",
        size: 1,
    },
    { 
        type: "number",
        value: "Cost",
        size: 2,
    },
    { 
        type: "text",
        value: "Department",
        size: 1,
        display: {display: 'block'}
    },
    { 
        type: "text",
        value: "Project",
        size: 1,
        display: {display: 'block'}
    },
    { 
        type: "text",
        value: "Project Split",
        size: 2,
        display: {display: 'block'}
    },
    { 
        type: "delete",
        value: "Delete",
        size: 1,
    }];

function ReceiptInfo(props) {

    const [rows, setRows] = useState(props.rows);
    const currentRows = []

    // Set display for Department columns
    if (props.columnSet.dept) {
        COLUMN_TYPES[3].display = {display: 'block'}
        DEFAULT_ROW_LAYOUT[3].display = {display: 'block'}
    } else {
        COLUMN_TYPES[3].display = {display: 'none'}
        DEFAULT_ROW_LAYOUT[3].display = {display: 'none'}
    }
    
    // Set display for Project and Project Split columns
    if (props.columnSet.project) {
        COLUMN_TYPES[4].display = {display: 'block'}
        COLUMN_TYPES[5].display = {display: 'block'}
        DEFAULT_ROW_LAYOUT[4].display = {display: 'block'}
        DEFAULT_ROW_LAYOUT[5].display = {display: 'block'}
    } else {
        COLUMN_TYPES[4].display = {display: 'none'}
        COLUMN_TYPES[5].display = {display: 'none'}
        DEFAULT_ROW_LAYOUT[4].display = {display: 'none'}
        DEFAULT_ROW_LAYOUT[5].display = {display: 'none'}
    }

    rows.forEach(
        (row, i) => {
            let autofillData = {};
            if (i < props.autofill.length) {
                autofillData = props.autofill[i];
            }

            currentRows.push(<GridRow key={i} autofill={autofillData} id={props.id + "_row_" + i} className={props.rowClassName} rowInfo={row} colsLayout={DEFAULT_ROW_LAYOUT}/>)
        }
    )
    
    return (
      <div id={props.id}>
        <Label className="grid_label">Receipt Information</Label>
        <Container className="grid">
            <GridRow className={props.headerClassName} colsLayout={COLUMN_TYPES}/>
            {currentRows}
        </Container>
        <AddItem onClick={(e) => 
          {
            e.preventDefault();
            rows.push(DEFAULT_ROW_LAYOUT);
            const newRows = [...rows];
            setRows(newRows)
          }}/>
      </div>
    )
  
  }


 

  export default ReceiptInfo;