import React from "react";
import {FormGroup} from "reactstrap";
/*
 * Add Item button for grids
 * Author: Andrew Pickard
 */

function AddItem(props) {
    return(
      <FormGroup className="add_item">
        <a href="" onClick={props.onClick}>Add Item
          <i className="fa-solid fa-plus" style={{width: '16px'}}></i>
        </a>
      </FormGroup>
    )
  }

export default AddItem;