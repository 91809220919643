import React from "react";
import { Route, BrowserRouter, Routes} from 'react-router-dom'
import './../css/default.css';
import Home from "./Home";
import EnterPurchase from "./EnterPurchase";

/*
 * Main component for now (might change depending on how I plan the components)
 * Author: Andrew Pickard AO 10/18/22
 */


function Main(props) {
 
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/enter_purchase' element={<EnterPurchase/>}/>
      </Routes>
    </BrowserRouter>
  )
  
}

export default Main
