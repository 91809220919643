import React from "react";
import {NavLink, Navbar} from "reactstrap";

/*
  Menu Component Container
  Contains Sidebar and Top bar
  Author: Andrew Pickard, AO 10/4/22
*/


// Default for now, might change when User Roles come in
// TODO: Get rid of this when privileges are setup
const nav = [
  [
    {
      icon: "fa-solid fa-plus",
      title: "Enter Purchase",
      target: "enter_purchase"
    },
    {
      icon: "fa-solid fa-gauge",
      title: "Dashboard",
      target: "dashboard"
    },
    {
      icon: "fa-solid fa-cart-shopping",
      title: "My Purchases",
      target: "my_purchases"
    },
    {
      icon: "fa-solid fa-magnifying-glass",
      title: "Search",
      target: "search"
    }
  ]
]


// Parent class for all menu 'components', such as sidebar and top bar
function Menu(props) {

    return(
      <div>
        <Sidebar links={nav[0]}>
        </Sidebar>
        <TopBar title={props.title} status={props.status}></TopBar>
      </div>
    )
  
}


// For Sidebar, may need to adjust for mobile
// Might export this to separate component file
function Sidebar(props) {

  let links = props.links.map((link, index) => {
    return (
        <NavLink className="sidebar_link" key={index} href={link.target}>
          <i className={link.icon} style={{width: '16px'}}></i>{link.title}
        </NavLink>
    )
  })

    return (
      <div id="sidebar">
        <img id="logo" src="./images/logo3.svg" />
        {links}
      </div>
    )
}

// For Top bar (Project Name, UN display, logout button, etc.)
// Might export this to separate component file
function TopBar(props) {
  return (
    <div className="topbar">
      <Navbar>
        <p className="project_name"><b>Receipt Minder</b></p>
        <p className="username">User Name / <a className="logout_button" href="login.html">Logout</a></p>
      </Navbar>
      <div className="status_bar">
        <h3 className="status_title">{props.title}</h3>
        <div className="status_break"></div>
        <h4 className="purchase_id">Purchase-xxxx</h4>
        <h4 className="purchase_status">{props.status}</h4>
      </div>
    </div>
  )
  
}

export default Menu;
