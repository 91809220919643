import React, {useState} from "react";
import {Container, Label} from "reactstrap";
import AddItem from "./AddItem.js";
import GridRow from "./GridRow.js";
/*
 * Tax Info grid for forms
 * TODO: merge this with ReceiptInfo and just use a Grid class
 */

const COLUMN_TYPES = [

    {
        type: "label",
        value: "Department",
        size: 2,
    },
    { 
        type: "label",
        value: "Tax Amount",
        size: 2,
    },
    { 
        type: "label",
        value: "Reason",
        size: 1,
    },
    { 
        type: "label",
        value: "State",
        size: 1,
    },
    { 
        type: "label",
        value: "Delete",
        size: 1,
    }];

const DEFAULT_ROW_LAYOUT = [
    {
        type: "text",
        value: "Department",
        size: 2,
    },
    { 
        type: "text",
        value: "Tax Amount",
        size: 2,
    },
    { 
        type: "text",
        value: "Reason",
        size: 1,
    },
    { 
        type: "text",
        value: "State",
        size: 1,
    },
    { 
        type: "delete",
        value: "Delete",
        size: 1,
    }];

function TaxInfo(props) {

    //console.log(props.autofill)

    const [rows, setRows] = useState(props.rows);
    const currentRows = []
    var displaySet = {display: "block"}
    
    if (props.display) {
        displaySet = {display: "none"}
    }

    rows.forEach(
        (row, i) => {
            let autofillData = {};
            if (i < props.autofill.length) {
                autofillData = props.autofill[i]
            }

            currentRows.push(<GridRow key={i} autofill={autofillData} id={props.id + "_row_" + i} className={props.rowClassName} rowInfo={row} colsLayout={DEFAULT_ROW_LAYOUT}/>)}
    )

    return (
      <div style={displaySet} id={props.id}>
        <Label className="grid_label">Tax Information</Label>
        <Container className="grid">
          <GridRow className={props.headerClassName} colsLayout={COLUMN_TYPES}/>

          {currentRows}
        </Container>
        <AddItem onClick={(e) => 
          {
            e.preventDefault();
            rows.push(DEFAULT_ROW_LAYOUT);
            const newRows = [...rows];
            setRows(newRows)
          }}/>
      </div>
    )
  
  }

export default TaxInfo

