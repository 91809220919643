import React, {useState} from "react";
import {Form, Label} from 'reactstrap';

import API from "../API";
import TextField from "./elements/TextField.js";
import ChoiceRadio from "./elements/ChoiceRadio.js";
import FormDropdown from "./elements/FormDropdown.js";
import FileUpload from "./elements/FileUpload.js";
import UploadModal from "./elements/UploadModal.js";
import TaxInfo from "./elements/TaxInfo.js";
import ReceiptInfo from "./elements/ReceiptInfo.js";
import CheckBox from "./elements/CheckBox.js";
import FormButtons from "./elements/FormButtons.js"
import axiosInstance from "axios";

/*
 *  Components for building the 'Enter Purchase' Form
 */

const HEADER_STYLE = "bg-dark border column_header grid_entry";
const ROW_STYLE = "bg-light border grid_entry";

// DUMMY VALUES FOR NOW, THESE WILL ACTUALLY COME FROM THE DB
const DEPARTMENTS = ["department1", "department2", "department3"]
const PROJECTS = ["project1", "project2", "project3"]
const CARDS = ["card1", "card2"]
const VENDORS = ["vendor1", "vendor2"]

// TODO: GET RID OF THESE
const ROW_DEFAULT_TAX = [
  {
      department: "department",
      taxAmount: "tax amount",
      reason: "reason",
      state: "state"
  }];

const ROW_DEFAULT_ITEMS = [
  {
      name: "name",
      quantity: "quantity",
      cost: "cost",
      department: "department",
      project: "project",
      projectSplit: "project split"
  }];
/*
 * =================================================
 *               MAIN FORM OBJECT
 * =================================================
 */
function FormEnterPurchase(props) {
    
    const [readOnly, setReadOnly] = useState(false);
    const [data, setData] = useState({});
    const [deptDisplay, setDeptDisplay] = useState(false)
    const [projectDisplay, setProjectDisplay] = useState(false)
    const [taxDisplay, setTaxDisplay] = useState(false)
    const [uploadModalDisplay, setUploadModalDisplay] = useState(false)

    /*
      For submitting the Enter Purchase Form
    */
    const submitForm = () => {
      //TODO: uhhhhhhhhhhh, finish this :/
    }

  
    /*
      For uploading receipts to 
    */
    const uploadReceipts = async (files) => {
      const response = await API.createReceiptEntry(files)
        .then((result) => {
          return result.json();
        }).then((data) => {
          return data;
        });
      
      setData(JSON.parse(response));

      if (response.status >= 200 && response.status < 300) {
        console.log(response.json());
      } else if (response.status === 400) {
        console.log(response.data);
      } else if (response.status === 500) {
        // TODO: Have this display an error message on the panel
        console.log("Error")
      }
    }

    /*
      Prepare Date
    */
    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); 
    var yyyy = date.getFullYear();

    date = yyyy + "-" + mm + "-" + dd;

    /*
      Prepare data for autofill (Receipt is uploaded)
    */

    var receiptItems = []
    var taxItems = [];
    var total = 0;

    if (Object.keys(data).length > 0) {
      receiptItems = data.items;
      taxItems = data.tax;
      total = data.total[0].total;
    }

    console.log(total);

    /*
      Prepare rows for items chart
      TODO: Update this to extend rows for autofill (just use an array of autofilled default items)
      TODO: Get rid of props.rows, we cant use that
    */
      var rows = {};
      var taxRows = {};

      if (!props.rows) {
        rows = ROW_DEFAULT_ITEMS
        taxRows = ROW_DEFAULT_TAX;
      } /*else {
        rows = props.rows
      }*/

    return (
      <div className="default_form">
        
        <div></div>

        <Label className="form_label">Purchase Details</Label>
        <Form className="form_contents">

          <div className="form_row first_form_row">
            <TextField autofill={"Test User"} name="Created By" type="text" id="createdBy" readonly="true"/>
            <TextField autofill={date} name="Created Date" type="date" id="createdDate" readonly="true"/>
          </div>

          <TextField name="Purchaser Notes" type="textarea" id="purchaserNotes" break="true" readonly={readOnly}/>

          <div className="form_row">
            <div style={{width: '50%'}}>
              <ChoiceRadio function={setDeptDisplay} name="Multiple Departments?"/>
              <FormDropdown display={deptDisplay} id="department_dropdown_base" className="form_row" name="Department" values={DEPARTMENTS}/>
            </div>
            <div>
              <ChoiceRadio function={setProjectDisplay} name="Multiple Projects?"/>
              <FormDropdown display={projectDisplay} className="form_row" name="Project" values={PROJECTS}/>
            </div>
          </div>
          
          <FileUpload  setDisplay={setUploadModalDisplay} name="Upload Receipt(s)"/>
          <UploadModal closeDisplay={() => setUploadModalDisplay(false)} display={uploadModalDisplay} uploadAction={(files) => uploadReceipts(files)} confirmLabel="Save" title="Upload" uploadBody/>

          <ReceiptInfo columnSet={{dept: deptDisplay, project: projectDisplay}} autofill={receiptItems}
          id="receipt_grid" headerClassName={HEADER_STYLE} rowClassName={ROW_STYLE} rows={[rows]}/>

          <ChoiceRadio function={setTaxDisplay} name="Sales Tax Exempt?"/>
          <TaxInfo display={taxDisplay} id="tax_grid" autofill={taxItems} headerClassName={HEADER_STYLE} rowClassName={ROW_STYLE} rows={[taxRows]}/>
          
          <div className="form_row">
            <FormDropdown name="Card Name" values={CARDS}/>
            <FormDropdown name="Vendor" values={VENDORS}/>
          </div>
          
          <TextField name="Total Cost" autofill={total} type="text" id="totalCost" readonly="true"/>
          <CheckBox className="form_row" description={"Does the total cost match the amount on the receipt?"}/>
          <FormButtons label1="Cancel" label2="Save Draft" label3="Create" action3={() => submitForm()}/>
        </Form>
      </div>
    )
    
  }


export default FormEnterPurchase 
