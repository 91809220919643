import React from "react";
import Menu from "./scenes/elements/Menu";
import FormEnterPurchase from "./scenes/FormEnterPurchase"
import './../css/default.css';

function EnterPurchase(props) {

  return (
    <div>
      <Menu title="New Purchase" status="Intake Purchase"/>
      <FormEnterPurchase/>
    </div>
  )
    
}
  
  export default EnterPurchase 