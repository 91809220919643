import React from "react";
import Menu from "./scenes/elements/Menu"
import './../css/default.css';

function Home(props) {
    return (
      <Menu title="Home" status=""/>
    )  
}
  
  export default Home 