import React, { useState } from "react";
import {Button, Input, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
/*
 * Modal for submit and file upload for forms
 * Author: Andrew Pickard
 */

function UploadModal(props) {
    var body = null;
    const currentFiles = [];

    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");


    /*
      For uploading receipts (adding them to selected files list)
    */
    const updateFiles = (e) => {
      for (let i=0; i<e.target.files.length; i++) {
        files.push(e.target.files[i])
      }
      const newFiles = [...files];
      setFiles(newFiles)
    }
    
    /*
      For uploading receipts (run through OCR)
    */
    const verifyUpload = () => {
      var verified = true;
      
      for (let i=0; i<files.length; i++) {

        let fileSplit = files[i].name.split(".");
        let fileType = fileSplit[fileSplit.length - 1].toLowerCase();

        if (!(fileType === "png" || fileType === "pdf" || fileType === "jpg" || fileType === "jpeg"))  {
          setErrorMessage("Please enter PDF, PNG, JPG, JPEG file types only");
          verified = false;
        }
      }

      // Add OCR HTTP Request here
      if (verified) {
        props.uploadAction(files);
        setFiles([]);
        setErrorMessage("");
        props.closeDisplay();
      }
    }

    /*
      For removing selected files from modal
    */
    const removeFromFiles = (i) => {
      let currentFiles = [...files];
      currentFiles.splice(i, 1);
      setFiles(currentFiles);
    }

    /*
      For image preview on receipt modal if needed
    */
    const previewImage = (file) => {
      window.open(URL.createObjectURL(file), 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1')
      const preview = document.getElementById("uploadPreview")
      preview.width = 400;
    }

    if (props.uploadBody) {
      files.forEach((file, i) => currentFiles.push(
        <div key={i} className="upload_receipt_item"><a href="" style={{marginRight: "10px", display: 'block', textAlign: 'left'}} onClick={() => {previewImage(file)}}>{file.name + "   "}</a><i className="fa-solid fa-x upload_x_button" onClick={() => removeFromFiles(i)}></i></div>
      ))
    
      body = (
        <div className="upload_body">
          <Input type="file" className="upload_input" id="receipt_upload" onChange={(e) => updateFiles(e)} multiple/>
          {currentFiles}
        </div>
      )
    } 

    return (
        <Modal isOpen={props.display}>
          <ModalHeader>{props.title + "    "}<div style={{color: "red", fontSize: "15px"}}>{errorMessage}</div></ModalHeader>
          <ModalBody> 
            {body}
          </ModalBody>
          <ModalFooter>
            <div className="flex_input">
              <Button className="anti_button" onClick={() => {setFiles([]); setErrorMessage(""); props.closeDisplay()}}>Cancel</Button>
            </div>
            <div className="flex_input">
              <Button className="right_buttons pro_button" onClick={() => {verifyUpload()}}>{props.confirmLabel}</Button>
            </div>
          </ModalFooter>
        </Modal>
    )

}

export default UploadModal