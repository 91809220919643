import React from "react";
import {FormGroup, Label, Input} from "reactstrap";
/*
 * Dropdown fields for forms
 * Author: Andrew Pickard
 */

function FormDropdown(props) {

  var displaySet = {display: 'block'}

  if (props.display) {
    displaySet = {display: 'none'}
  }

  return (
      <FormGroup style={displaySet} className={props.className + " flex_input dropdown"}>
        <Label className="input_label">{props.name}</Label>
        <DropDownInput id={props.id} values={props.values}/>  
      </FormGroup>
    )
  }


function DropDownInput(props) {

    const currentVals = []

    props.values.forEach((value, i) => {
        currentVals.push(<option value={value} key={i}>{value}</option>)
    })

    return (
        <Input id={props.id} className="dropdown_select" type="select">   
          <option value=""></option>
          {currentVals}
        </Input>
    )

}

export default FormDropdown