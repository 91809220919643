import React from "react";
import {Row, Col} from "reactstrap";
import FormDropdown from "./FormDropdown.js";
import TextField from "./TextField.js";

/*
 * Row component used for Grids in UI
 */

const inputSet = {
    makeDropdown: (props) => {return FormDropdown(props)},
    makeTextField: (props) => {return TextField(props)},
    makeDelete: (id) => {return (<a onClick={(e) => removeRow(id, e)}><i className="fa-solid fa-trash-can"></i></a>)}
}

function GridRow(props) {

    const colsLayout = []
    var itemName = "";
    var cost = "";

    if (props.autofill) {
        itemName = props.autofill.name;
        cost = props.autofill.amount;
    }

    if (props.colsLayout) {
        for(let i=0; i<props.colsLayout.length; i++) {
            let input = null;
            let field_props = props.colsLayout[i];
            field_props.gridField = "true"

            if (field_props.type === "dropdown") {
                input = inputSet.makeDropdown(field_props);
            } 
            else if (field_props.type === "text" || field_props.type === "number") {
                
                // For Autofill, autofill values based on what the value should be
                if (field_props.value === "Item Name") {
                    field_props.autofill = itemName;
                } else if (field_props.value === "Cost" || field_props.value === "Tax Amount") {
                    field_props.autofill = cost;
                }

                input = inputSet.makeTextField(field_props);
            } 
            else if (field_props.type === "delete") {
                input = inputSet.makeDelete(props.id);
            }
            else if (field_props.type === "label") {
                input = field_props.value
            }

            colsLayout.push(
                <Col style={field_props.display} key={i} className={props.className} xs={field_props.size}>
                    {input}
                </Col>
            );
        }
    }

    return(
        <Row id={props.id}>
            {colsLayout}
        </Row>
    )

}

function removeRow(id, e) {
    e.preventDefault();
    console.log(id);
    document.getElementById(id).remove();
}


export default GridRow;