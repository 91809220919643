import React from "react";
import {FormGroup, Input} from "reactstrap";
/*
 * checkbox for forms
 * Author: Andrew Pickard
 */

function CheckBox(props) {
    return(
        <FormGroup className={props.className + " add_item "}>
        {props.description}<Input type="checkbox" className="checkbox"/>Yes
        </FormGroup>
    )
}

export default CheckBox;